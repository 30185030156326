<template>
  <component :is="fetchData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="fetchData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching data
      </h4>
      <div class="alert-body">
        No data found with this id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'organization-list'}"
        >
          Organization List
        </b-link>
        for other organizations.
      </div>
    </b-alert>

    <b-tabs
      pills
    >

        <h4 class="d-none d-sm-inline">Edit Organization</h4>

        <org-edit-tab-account
          v-if="fetchData"
          :fetch-data="fetchData"
          @refetch-data="refetchData"
          class="mt-2 pt-75"
        />

    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import orgStoreModule from '../orgStoreModule'
import OrgEditTabAccount from './OrgEditTabAccount.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    OrgEditTabAccount,
  },
  methods: {
    refetchData() {
      store.dispatch('app-org/fetchOrgProfile')
      .then(response => { fetchData.value = response.data.data })
      .catch(error => {
          fetchData.value = undefined
      })
    }
  },
  setup() {
    const fetchData = ref(null)

    const ORG_APP_STORE_MODULE_NAME = 'app-org'

    // Register module
    if (!store.hasModule(ORG_APP_STORE_MODULE_NAME)) store.registerModule(ORG_APP_STORE_MODULE_NAME, orgStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ORG_APP_STORE_MODULE_NAME)) store.unregisterModule(ORG_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-org/fetchOrgProfile')
      .then(response => { fetchData.value = response.data.data })
      .catch(error => {
        if (error.response.status === 404) {
          fetchData.value = undefined
        }
      })

    return {
      fetchData,
    }
  },
}
</script>

<style>

</style>
